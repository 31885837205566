:root {
  --green500: #027847;
  --green600: #03654f;
  --green800: #035743;
  --green900: #023e34;
  --black700: #222222;
  --shadow: #cacecece;
  --red500: #d60b00;
  --pink: #d81b60;
  --purple800: #4527a0;
  --purple600: #3949ab;
  --orange600: #ff7700;
  --yellow500: #ffeb3b;
  --aqua500: #026e78;
  --blue500: #006aec;
  --white700tr: rgb(238 238 238 / 60%);
}

input {
  padding: 8px 4px;
  border: 1px solid var(--green600);
  border-radius: 4px;
}

.step {
  transition: all 0.5s ease-in;
}

.d-error {
  color: var(--red500);
}

a {
  color: var(--green500);
}
